<template>
  <div class="container ">
     <el-form :inline="true">
         <el-form-item>
        <el-button type="primary" @click="toCreate">发起教练申请</el-button>
      </el-form-item>
     </el-form>
    <auto-table
      :DataList="DataList"
      :option="Option"
      :total="Total"
      @changePage="getList"
    >
      <template #type>
        <div>
          教练IP服务
        </div>
      </template>
        <template #coach="{ row }">
        <div>
          {{row.map.coach.real_name}}
        </div>
      </template>
      <template #status="{ row }">
        <div>
          <el-tag type="success" size="small" v-if="row.status === 1"
            >服务中</el-tag
          >
          <el-tag type="info" size="small" v-if="row.status === 2"
            >未到账</el-tag
          >
        </div>
      </template>
       <template #time="{ row }">
        <div>
          开始时间:{{ row.effect_time  }}
        </div>
        <div>
          结束时间:{{ row.expire_time  }}
        </div>
      </template>
    </auto-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      DataList: [],
      Option: [
        { name: "标题", value: "title" },
          { name: "教练", value: "coach", type: "custom" },
        { name: "类型", value: "type", type: "custom" },
          { name: "金额", value: "amount" },
        { name: "状态", value: "status", type: "custom"  },
      
        { name: "服务时间", value: "time", type: "custom" ,width:'230px'},
        { name: "提交时间", value: "create_time"},
      ],
      Total: 0,
      type: "",
    };
  },

  mounted() {
    this.getList(1)
  },

  methods: {
    toCreate(){
        this.$router.push({
            name:'coachApplyip'
        })
    },
    //获取列表
    getList(v) {
      let page = v || 1;
      this.$axios({
        url: "/user/coachServiceRecord/queryManagerListPage",
        params: {
          currentPage: page,
          pageSize: 10,
        },
      }).then((res) => {
        this.DataList = res.data.data.rows;
        this.Total = res.data.data.total;
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>